@media only screen and (max-width: 768px) {
    .purchase-event-ticket {
        
        .next-buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5vw;
            width: 100% !important;
            height: fit-content;
            z-index: 5;
            color: white;
            position: relative;
    
            .mobile-next-button {
                padding: 2vw;
                background: linear-gradient(to bottom right, #FB4F8C, #510641);
                color: white;
                border-radius: 1vw;
                font-size: 6vw !important;
            }
        }

        .check-out {
            top: 5vh;
            left: 3vw;
            width: 90vw;
            min-height: 45vh;
            height: 80vh;
            display: grid;
            grid-template-rows: 74vh 20vh;

            .tickets-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 74vh;
                overflow-y: auto;
                width: 100%;
                margin-bottom: 2vw !important;

                .padding {
                    margin-top: 110vh;
                }

                .ticket {
                    width: 95%;
                    margin-top: 5vw !important;

                    .title-amount {
                        .title-count {
                            .title {
                                font-size: 5vw;
                            }

                            .count {
                                div {
                                    font-size: 5vw;
                                    width: 4.5vw;
                                    height: 4.5vw;
                                }
                            }
                        }

                        .cost {
                            font-size: 4vw !important;
                        }
                    }

                    .desc {
                        font-size: 3vw;
                    }
                }
            }

            .check-out-form {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                height: 74vh;
                overflow-y: auto;
                width: 90vw;
                margin-bottom: 2vw !important;

                .content-container {
                    width: 86vw;
                    min-height: 50vh;
                    max-height: 50vh !important;

                    .slide {

                        .content {
                            width: 90vw;
                            margin-bottom: 2vw;

                            &.checkout {
                                max-height: 70vw;

                                .content-item {
                                    font-size: 25vh;
                                    margin-top: 3vh;
                                    margin-bottom: 3vh;
                                    
                                    grid-template-columns: 40vw 25vw 15vw;

                                    .title, .price, .total {
                                        font-size: 4.5vw;
                                    }
                                }
                            }

                            .StripeElement {
                                width: 70vw;
                                height: 70vw;
                            }
                        }
                    }

                    .form-group {
                        width: 90vw;

                        input {
                            font-size: 6vw;
                            height: 20vw;
                            margin-bottom: 2vw;
                            margin-top: 2vw;
                            width: 80vw;
                            border-radius: 2vw;
                        }
                    }
                }

                .button-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .button {
                        font-size: 7vw;
                        margin-bottom: 3vw;
                        width: 30vw;
                    }
                }
            }
        }
    }
}
