@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

.event-page-container {
    .header {
        .navbar-signed,
        .navbar-unsigned {
            z-index: 2;
        }

        .banner {
            width: 99vw;
            height: 70vh;
            position: relative;
            top: -7vh;
        }

        .title-stats {
            width: fit-content;
            padding: 2vw;
            margin-right: auto;
            margin-left: auto;
            margin-top: -8vh;

            .title {
                width: fit-content;
                background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                text-align: center;
                font-size: 5.5vw;
                font-family: "Inknut Antiqua";
            }

            .small-stats {
                width: 80%;
                margin-right: auto;
                margin-left: auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 2vw;
                margin-top: 1vw;

                .stats {
                    background: linear-gradient(to bottom right, #fb4f8c, #51062a);
                    color: white;
                    font-size: 1.5vw;
                    font-family: "Inknut Antiqua";
                    padding: 1vw;
                    width: 10vw;
                    text-align: center;
                    border-radius: 2vw;
                    cursor: pointer;

                    &:hover {
                        background: linear-gradient(to bottom left, #fb4f8c, #51062a);
                    }
                }
            }
        }
    }

    .seperator {
        height: 0.1vw;
        background: linear-gradient(to bottom right, #eb3072, #2e041e);
        border-radius: 1vw;
        margin-bottom: 2vw;
    }

    .empty {
        width: fit-content;
        background-image: linear-gradient(to bottom left, #FFE83F, #F15BB5);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        margin-right: auto;
        margin-left: auto;
        font-size: 3vw;
        font-family: "Expletus Sans";
        text-align: center;
    }

    .reviews {
        .review-summary {
            background: #151515;
            width: 50vw;
            height: 22vw;
            margin-right: auto;
            margin-left: auto;
            border-radius: 1vw;
            margin-bottom: 2vw;

            .rating {
                background-image: linear-gradient(to bottom right, #fb4f8c, #51062a);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                font-size: 5vw;
                text-align: center;
                font-family: "Expletus Sans";
                width: fit-content;
                margin-right: auto;
                margin-left: auto;
                margin-top: 0.5vw;
            }

            .star-wrapper {
                font-size: 6vw;
                width: fit-content;
                height: fit-content;
                margin-right: auto;
                margin-left: auto;
            }

            .context {
                color: black;
                font-size: 4vw;
                text-align: center;
                margin-bottom: 0.5vw;
            }
        }

        .content {
            display: flex;
            flex-direction: column !important;
            
            .event-review-card {
                width: 55vw;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 2vw;
                margin-top: 2vw;

                .date-complete {
                    position: relative;
                    bottom: -3.5vw;
                }
            }
        }
    }

    .photo-gallery {
        display: grid;
        grid-template-columns: 25vw 25vw 25vw;
        padding: 5vw;
        justify-content: space-between;
        margin-top: -10vw;

        .photo {
            .card {
                background: linear-gradient(to bottom right, #B7B7B7, #0000008e, #000000);;
                position: relative;
                top: 5.5vw;
                left: 1.2vw;
                width: fit-content;
                padding: 1vw;
                border-radius: 3vw;
                color: white;
                font-size: 1.5vw;
                cursor: pointer;
            }

            img {
                width: 25vw;
                height: 25vw;
                border-radius: 3vw;
                padding: 1vw;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95vw;
        margin-right: auto;
        margin-left: auto;

        .price-badge {
            background: #ffffff;
            box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.25);
            border-radius: 1vw;
            padding: 2vw;
            height: 11vw;
            position: sticky;
            top: 30vw;

            .range {
                background-image: linear-gradient(to bottom right, #fb4f8c, #51062a);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                font-size: 3vw;
                text-align: center;
                font-family: "Expletus Sans";
                margin-bottom: 1vw;
            }

            .button {
                background: linear-gradient(to bottom right, #970a78, #d21a1c 99%);
                border-radius: 1vw;
                padding: 2vw;
                font-size: 2vw;
                text-align: center;
                align-items: center;
                font-family: "Expletus Sans";
                color: white;
                width: 15vw;
                height: 2.5vw;
                cursor: pointer;

                &:hover {
                    background: linear-gradient(to bottom left, #970a78, #d21a1c 99%);
                }
            }
        }

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50vw;
            margin-top: 2vw;

            .container {
                background: #ffffff;
                box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.212);
                border-radius: 1vw;
                padding: 1vw;
                margin-bottom: 3vw;
                height: 3.5vw;
                display: flex;
                flex-direction: row;
                gap: 2vw;

                svg {
                    width: 3vw;
                    height: 3vw;
                    margin-top: auto;
                    margin-bottom: auto;
                    background: linear-gradient(to bottom right, #F34A4A, #AA49CC);
                    padding: 0.5vw;
                    border-radius: 1.5vw;
                }

                .date,
                .loc-text {
                    margin-top: auto;
                    margin-bottom: auto;
                    color: black;
                    font-size: 2vw;
                    justify-self: right;
                    font-family: "Expletus Sans";
                }

                &.loc {
                    cursor: pointer;
                }

                &.desc {
                    min-height: 10vw;
                    height: fit-content;
                    padding: 1vw;
                    box-shadow: 0.5vw 0.5vw 0.2vw 0.2vw rgba(0, 0, 0, 0.103); /* horizontal-offset vertical-offset blur spread color */
                    color: black;
                    font-family: "Expletus Sans";
                    font-size: 1vw;

                    &.mobile {
                        display: none;
                    }
                }

                &.org {
                    height: 3.5vw;

                    img {
                        width: 3.5vw;
                        height: 3.5vw;
                        border-radius: 2vw;
                        margin-top: auto;
                        margin-bottom: auto;
                    }

                    .details {
                        width: 25vw;

                        .title {
                            color: black;
                            font-size: 1.5vw;
                            text-align: center;
                            font-family: "Expletus Sans";
                        }

                        .small-stats {
                            width: fit-content;
                            display: flex;
                            flex-direction: row;
                            gap: 1vw;
                            margin-right: auto;
                            margin-left: auto;

                            .stats {
                                background-image: linear-gradient(to bottom right,
                                        #fb4f8c 40%,
                                        #730794);
                                -webkit-background-clip: text;
                                background-clip: text;
                                color: transparent;
                                font-size: 1vw;
                                text-align: center;
                                font-family: "Expletus Sans";
                                margin-top: auto;
                                margin-bottom: auto;
                            }

                            .bullet {
                                width: 0.5vw;
                                height: 0.5vw;
                                border-radius: 100%;
                                background: white;
                                margin-top: auto;
                                margin-bottom: auto;
                            }
                        }
                    }

                    .follow {
                        background: linear-gradient(to bottom right, #970a78, #d21a1c 99%);
                        border-radius: 1vw;
                        padding: 1vw;
                        font-size: 2vw;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        font-family: "Expletus Sans";
                        color: white;
                        width: 10vw;
                        height: 1.7vw;
                        cursor: pointer;

                        &:hover {
                            background: linear-gradient(to bottom left, #970a78, #d21a1c 99%);
                        }
                    }
                }
            }
        }
    }

    .signup-container {
        z-index: 5;
        position: fixed;
        top: 0;
        background: #00000088;
        width: 100vw;
        height: 100vh;
        align-items: center;
        display: flex;

        .wrapper {
            width: fit-content;
            padding: 2vw;
            border-radius: 2vw;background: linear-gradient(
                to bottom right,
                #DF4B4B, #D74CA8, #8B1B8E, #4D094E
            );
            text-align: center;
            margin-right: auto;
            margin-left: auto;

            .banner {
                .title {
                    color: white;
                    font-size: 2vw;
                }
            }
        }
    }
}