@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap');

.purchase-event-ticket {
    position: fixed;
    top: 0;
    z-index: 3;

    .shade {
        width: 99vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.72);
        cursor: pointer;
    }

    .check-out {
        z-index: 4;
        background: #070707;
        position: fixed;
        top: 6vw;
        left: 25vw;
        width: 50vw;
        height: 30vw;
        border-radius: 1vw;
        padding: 1vw;
        display: grid;
        grid-template-rows: 26vw 10vw;

        .next-buttons-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5vw;
            width: 50vw;
            height: fit-content;
            z-index: 5;
            color: white;
            position: relative;
    
    
            .mobile-next-button {
                padding: 1vw;
                background: linear-gradient(to bottom right, #FB4F8C, #510641);
                color: white;
                border-radius: 1vw;
                font-size: 2vw;
                cursor: pointer;
            }
        }

        .tickets-container {
            width: 50vw;
            height: 25vw;
            overflow-y: auto;

            .ticket {
                background: #181818;
                width: 80%;
                border-radius: 1vw;
                padding: 2vw;
                margin-right: auto;
                margin-left: auto;
                margin-top: 2vw;
                margin-bottom: 2vw;
                font-family: "Expletus Sans";

                .title-amount {
                    .title-count {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                       
                        .title {
                            color: white;
                            font-size: 2vw;
                        }

                        .count {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            gap: 2vw;

                            div {
                                font-size: 1.5vw;
                                color: white;
                                margin-top: auto;
                                margin-bottom: auto;
                                padding: 0.5vw;
                                border-radius: 0.5vw;
                                height: 1vw;
                                width: 1vw;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .minus {
                                background: gray;
                                color: white;
                                cursor: pointer;

                                &:hover {
                                    background: #181818;
                                    border: 1px solid white;
                                }
                            }

                            .plus {
                                background: linear-gradient(to bottom right, #FB4F8C, #510641);
                                color: white;
                                cursor: pointer;

                                &:hover {
                                    background: linear-gradient(to top right, #FB4F8C, #510641);
                                }
                            }
                        }
                    }

                    .cost-limit {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 1vw;
                        
                        .cost {
                            background-image: linear-gradient(to bottom right, #FB4F8C, #51062A);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            font-size: 1.5vw;
                            margin-bottom: 1vw;
                            margin-top: 1vw;
                        }

                        .stats {
                            background:  linear-gradient(to bottom right, #FB4F8C, #51062A);
                            color: white;
                            font-size: 1vw;
                            width: fit-content;
                            padding: 0.5vw;
                            border-radius: 2vw;
                        }
                    }
                }

                .desc {
                    color: white;
                    font-size: 1vw;
                }
            }
        }

        &-form {
            width: 50vw;
            height: 25vw;

            .content-container {
                width: 50vw;
                height: 25vw;
                margin-top: 0vw;
                overflow-y: auto;
                overflow-x: hidden;
                
                .slide {
                    &.paymentflow {
                        position: relative;
                        top: 6vw;
                        text-align: center;
                    }

                    .content {

                        &.checkout {
                            display: flex;
                            flex-direction: column;
                            width: 50vw;
                            max-height: 25vw;
                            overflow-y: auto;
                            margin-bottom: 1vw;

                            .content-item {
                                border-bottom: 1px solid white;

                                display: grid;
                                grid-template-columns: 18vw 15vw 16vw;

                                gap: 2vw;

                                .title {
                                    color: white;
                                    font-size: 1.5vw;
                                    margin-top: 1vw;
                                    margin-bottom: 1vw;
                                }

                                .price {
                                    color: white;
                                    font-size: 1.5vw;
                                    margin-top: 1vw;
                                    margin-bottom: 1vw;
                                    text-align: right;
                                    padding-right: 1vw;
                                    border-radius: 0.5vw;
                                    margin-right: auto;
                                    margin-left: auto;
                                }

                                .total {
                                    color: white;
                                    font-size: 1.5vw;
                                    margin-top: 1vw;
                                    margin-bottom: 1vw;
                                    text-align: right;
                                    padding-right: 1vw;
                                    border-radius: 0.5vw;
                                    margin-right: auto;
                                    margin-left: auto;
                                }
                            }
                        }

                        .StripeElement {
                            border: 1px solid white;
                            margin-left: 1.5vw;
                            width: 50vw !important;
                            height: 40vw !important;
                        }
                    }

                    &.inactive {
                        .header {
                            svg {
                                transform: rotate(-180deg);
                            }
                        }

                        .content {
                            display: none;
                        }
                    }
                }
    
                .form-group {
                    margin-bottom: 2vw;
                    width: 50vw;
                    display: flex;
                    flex-direction: column;
    
                    input {
                        background: #181818;
                        width: 45vw;
                        height: 4vw;
                        padding: 1vw;
                        font-size: 2vw;
                        border-radius: 1vw;
                        margin-bottom: 0.5vw;
                        margin-top: 0.5vw;
                        margin-right: auto;
                        margin-left: auto;
                        color: white;
                    }
                }
            }
        }
    }
}
