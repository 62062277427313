@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");

.EventsCard {
    background: #ffffff;
    width: 28vw;
    max-height: 56vh;
    height: fit-content;
    border-radius: 1vw;
    cursor: pointer;
    box-shadow: 10px 11px 20px rgba(0, 0, 0, 0.25);
    scrollbar-color: white;

    .extra-card {
        position: relative;
        width: 28vw;
        height: 0;

        .event-today {
            background: linear-gradient(to bottom right, #ff5de5, #ef4444);
            padding: 1vw;
            width: fit-content;
            color: white;
            border-radius: 1vw;
            position: relative;
            left: 18vw;
            top: 0.5vw;
            font-size: 1vw;
            text-align: center;
            font-family: "Expletus Sans";

            &:hover {
                background: linear-gradient(to bottom left, #ff5de5, #ef4444);
            }
        }

        .event-passed {
            background: linear-gradient(to bottom right, #ff5de5, #ef4444);
            color: white;
            border-radius: 1vw;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 3vw;
            position: relative;
            top: 7.5vw;
            left: 2vw;
            width: 26vw;
            height: 7vw;
            transform: rotate(40deg);

            &:hover {
                background: linear-gradient(to bottom left, #ff5de5, #ef4444);
            }
        }
    }

    .banner {
        width: 28vw;
        height: 30vh;
        border-radius: 1vw;
    }

    .title {
        color: white;
        font-size: 2vw;
        text-align: center;
        font-family: "Expletus Sans";
        margin-top: 2vh;
    }

    .desc {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
        color: rgb(0, 0, 0);
        text-align: center;
        font-size: 1vw;
        font-family: "Inknut Antiqua";

        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show before truncating */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 7vw; /* Adjust this based on line height and number of lines */
        line-height: 1.5vw; /* Adjust this based on your text's line height */
    }

    .loc-url {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        margin-right: auto;
        margin-left: auto;
        margin-top: .5vw;
        margin-bottom: 1vw;

        .loc-C {
            display: flex;
            flex-direction: row;
            gap: 0.3vw;

            .orgName {
                background: linear-gradient(to bottom right, #fb4f8c, #51062a);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                font-family: "Inknut Antiqua";
                font-size: 1vw;
                margin-top: auto;
                margin-bottom: auto;
            }

            img {
                width: 2vw;
                height: 2vw;
                border-radius: 2vw;
            }
        }

        .bullet {
            width: 1vw;
            height: 1vw;
            border-radius: 2vw;
            margin-top: auto;
            margin-bottom: auto;
            background: linear-gradient(to bottom right, #D73EE4, #FF1414 100%);
        }

        .date {
            background: linear-gradient(to bottom right, #D73EE4, #FF1414 100%);
            font-family: "Inknut Antiqua";
            font-size: 1vw;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            margin-top: auto;
            margin-bottom: auto;
        }

        .cost-range {
            background-image: linear-gradient(to bottom right, #fb4f8c, #51062a);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-size: 1vw;
            margin-top: auto;
            margin-bottom: auto;
            font-family: "Inknut Antiqua";
        }

        .url-ico {
            width: 2vw;
            height: 2vw;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}